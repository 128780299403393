import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';
import './Itinerary.css';
// Correction de l'affichage des icônes des marqueurs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const CenterMap = ({ positions }) => {
  const map = useMap();

  useEffect(() => {
    if (positions.length > 0) {
      const bounds = L.latLngBounds(positions.map(position => position.position));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [positions, map]);

  return null;
};


const Itinerary = ({ itineraryData }) => {
  const mapRef = useRef(null);

  const positions = itineraryData.proposals[0].days.map(day => ({
    name: day.location,
    position: day.coordinates,
  }));

  const createCustomIcon = (index) => {
    return L.divIcon({
      html: `<div style="background-color: #2E86C1; color: white; border-radius: 50%; width: 25px; height: 25px; display: flex; align-items: center; justify-content: center;">${index}</div>`,
      className: "",
      iconSize: [25, 25]
    });
  };

  const assignGlobalSteps = (cities) => {
    const citySteps = [];
    const visitedCities = new Map();

    cities.forEach((city, index) => {
      if (!visitedCities.has(city)) {
        visitedCities.set(city, []);
      }
      visitedCities.get(city).push(index + 1);
    });

    let stepCounter = 1;
    visitedCities.forEach((days, city) => {
      citySteps.push({ city, step: stepCounter, days });
      stepCounter++;
    });

    return citySteps;
  };

  const citySteps = assignGlobalSteps(positions.map(coord => coord.name));

  useEffect(() => {
    if (positions.length > 1 && !mapRef.current) {
      mapRef.current = L.map('map').setView(positions[0].position, 6);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);

      const waypoints = positions.map(pos => L.latLng(pos.position[0], pos.position[1]));

      const routingControl = L.Routing.control({
        waypoints,
        routeWhileDragging: true,
        createMarker: function(i, wp, nWps) {
          const cityStep = citySteps.find(step => step.city === positions[i].name);
          return L.marker(wp.latLng, { icon: createCustomIcon(cityStep.step) }).bindPopup(`
            <strong>${positions[i].name}</strong><br />
            Days: ${cityStep.days.join(', ')}
          `);
        },
        show: false,
        addWaypoints: false,
        fitSelectedRoutes: false,
        lineOptions: {
          styles: [{ color: 'blue', opacity: 0.6, weight: 4 }],
        }
      }).addTo(mapRef.current);

      routingControl.on('routesfound', function(e) {
        const summary = e.routes[0].summary;
        console.log('Distance totale : ' + summary.totalDistance + ' mètres');
        console.log('Temps de trajet total : ' + summary.totalTime + ' secondes');
      });
    }
  }, [positions, citySteps]);

  if (!positions.length) {
    return <p>Pas d'itinéraire disponible</p>;
  }

  return (
    <div>
      <div id="map" style={{ height: "200px", width: "100%" }}></div>
    </div>
  );
};

export default Itinerary;