import React from 'react';
import './UnderConstruction.css'; // Le fichier CSS pour le style

function UnderConstruction() {
  return (
    <div className="uc-container">
      {/* Intégration de la vidéo en fond */}
      <video autoPlay muted loop className="uc-background-video">
        <source src="/Background.mov" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="uc-content">
        <h1 className="uc-h1">FlyManta</h1>
        <p className="uc-p">Votre voyage, à votre image.<br /> Bientôt disponible !</p>
        <footer className="uc-footer">&copy; 2024 FlyManta. Tous droits réservés.</footer>
      </div>
    </div>
  );
}

export default UnderConstruction;
