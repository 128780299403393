import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n';
import { auth } from './firebase';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Questionnaire from './components/Questionnaire';
import Loading from './components/Loading';
import AIResponse from './components/AIResponse';
import { SpeedInsights } from "@vercel/speed-insights/react";
import UnderConstruction from './components/UnderConstruction'; 

function AppContent() {
  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem('answers');
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user || null);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem('answers', JSON.stringify(answers));
  }, [answers]);

  // Détecte si l'application est en mode production
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <div className="App">
      <Routes>
        {isProduction ? (
          <Route path="*" element={<UnderConstruction />} />
        ) : (
        <>
          <Route exact path="/" element={<Home user={user} />} />
          <Route path="/questionnaire" element={<Questionnaire setAnswers={setAnswers} />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/travel/:itineraryId" element={<AIResponse />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
      </Routes>
      <SpeedInsights />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
