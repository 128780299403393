import React from 'react';
import { createRoot } from 'react-dom/client'; // Remplace ReactDOM par createRoot
import './index.css';
import App from './App';

const container = document.getElementById('root'); // Sélectionne l'élément root
const root = createRoot(container); // Crée la nouvelle racine

root.render(
    <App />
);
